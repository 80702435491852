import React, { useState, useEffect, useRef } from 'react'
import {
    Select,
    MenuItem,
    createTheme,
    ThemeProvider,
    Grid,
    Divider,
    Snackbar,
    IconButton,
    Box
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import { DashboardGalleryThumbnail, DashboardPageDetail, DashboardMetadataDetail } from '../../interfaces/DashboardMetadata'
import CustomContainer from '../../components/shared/CustomContainer/CustomContainer'
import style from './DashboardPreview.module.css'
import { mergeThemes } from '../../theme/merge'
import { textbox_theme } from '../../theme/textbox'
import { register_textbox_theme } from '../../theme/register'
import { dashboard_card, select_theme } from '../../theme/dashboard'
import Iframe from 'react-iframe'
import line_logo from "./../../assets/footer/line.svg"
import share from '../../assets/dashboard/share.svg'
import TVLButton from '../../components/shared/TVLButton/TVLButton'
import DashboardCard from '../../components/shared/DashboardCard/DashboardCard'
import CloseIcon from '@mui/icons-material/Close';
import SharePopup from '../../components/shared/SharePopup/SharePopup'
import { bookmarkDashboard, dashboard_details, unbookmarkDashboard, extendUuid } from '../../services/data-connector'
import DashboardSkeleton from './DashboardSkeleton/DashboardSkeleton'
import DashboardTagElement from '../../components/shared/DashboardTagElement/DashboardTagElement'
import BookmarkElement from '../../components/shared/BookmarkElement/BookmarkElement'
import { SnackbarMessage } from '../../interfaces/Snackbar'
import { BookmarkAction } from '../../utils/dashboard'
import { selectAccessToken, selectAccessTokenError, selectAccessTokenStatus, selectExpirationAt, fetchAccessToken } from '../../redux/features/authentication/authenticationSlice'
import { selectDashboardMetadata, selectDashboardStatus, dashboardMetadataAdded } from '../../redux/features/dashboard/dashboardSlice'
import { updateDashboardMetadataBookmark } from '../../utils/dashboard'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { STATUS } from '../../variable/enum'
import LogInPopUp from '../../components/shared/LogInPopUp/LogInPopUp'
import { getUserAccount } from '../../utils/login'
import { formatDate } from '../../utils/formatDate'
import { dynamicSelectorTheme } from '../../theme/dynamicSelector'

const DashboardPreview2 = () => {
    const { id } = useParams<{ id: string }>()
    const [pageName, setPageName] = useState<string>('')
    const [isHover, setIsHover] = useState<boolean>(false)
    const [isMultipleType, setIsMultipleType] = useState<boolean>(false)
    const [isBookmarked, setIsBookmarked] = useState<boolean>(false)
    const [openSharePopup, setOpenSharePopup] = useState<boolean>(false)
    const [uuid, setUuid] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const path = window.location.pathname
    // user login
    const user = getUserAccount()
    const [openLogInPopUp, setOpenLogInPopUp] = useState<boolean>(false)
    const INITIAL_DASHBOARD_LIST: DashboardPageDetail = {
        embededLink: "",
        pageId: id,
        link: "",
        pageTitle: "",
        uuids: [],
        pageName: "",
        pageNameEng: "",
        isBookmarked: false,
        params: {}
    }
    const INITIAL_DASHBOARD_METADATA: DashboardMetadataDetail = {
        imgUrl: "",
        shortDescription: "", 
        galleryTitle: "",
        galleryId: id,
        galleryType: "single",
        tags: [],
        fullDescription: "",
        galleryPages: [INITIAL_DASHBOARD_LIST],
        createdDate: '',
        pageList: [{pageName: 'ภูเก็ต', link: ""}],
        galleryCode: ""
    }

    const [dashboardGallery, setDashboardGallery] = useState<DashboardMetadataDetail>(
        INITIAL_DASHBOARD_METADATA
    )
    const [dashboardPage, setDashboardPage] = useState<DashboardPageDetail>(
        INITIAL_DASHBOARD_LIST
    )
    const [similarDashboards, setSimilarDashboards] = useState<
        DashboardGalleryThumbnail[]
    >([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const accessToken = useSelector(selectAccessToken)
    const accessTokenStatus = useSelector(selectAccessTokenStatus)
    const accessTokenError = useSelector(selectAccessTokenError)
    const expiresAt = useSelector(selectExpirationAt)
    const dashboardMetadata = useSelector(selectDashboardMetadata)
    const dashboardStatus = useSelector(selectDashboardStatus)
    const dispatch = useDispatch<AppDispatch>()

    const handleDashboardDetailAPI = async () =>{
        const { success, res } = await dashboard_details(id, accessToken)
            if(success){
                let dashboardMetadataTmp: DashboardMetadataDetail = res['dashboard_metadata']
                setDashboardGallery(dashboardMetadataTmp)
                setIsMultipleType(dashboardMetadataTmp.galleryType === 'multiple')
                setDashboardPage(dashboardMetadataTmp.galleryPages[0])
                setSimilarDashboards(res['similar_dashboards'])
                setIsBookmarked(dashboardMetadataTmp.galleryPages[0].isBookmarked)
                setPageName(dashboardMetadataTmp.galleryPages[0].pageName)
                if(res.uuid){
                    setUuid(res.uuid)
                }
                setIsLoading(false)
            }
            else{
                console.log(res.error)
            }
    }
    useEffect(() => {
        const fetchData = async () => {
            if(user.getIsLoggedIn() && accessTokenStatus === STATUS.IDLE){
                dispatch(fetchAccessToken())
            } else if(user.getIsLoggedIn() && accessTokenStatus === STATUS.SUCCEEDED && !dashboardGallery.galleryTitle){
                handleDashboardDetailAPI()
            } else if(!user.getIsLoggedIn()){
                setOpenLogInPopUp(true)
            }
        }
        fetchData()
      },[accessToken])
      const mergedTheme = mergeThemes(textbox_theme, register_textbox_theme, dashboard_card, select_theme)
      const theme = createTheme(mergedTheme)

      
      const handleProvinceURL = (targetPageName: string) => {
        let matchedPage = dashboardGallery.pageList.find(({pageName}) => pageName === targetPageName)
        if(matchedPage){
            window.location.href = matchedPage.link
        }
      }

      // Setup Snackbar stuff
    
    const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
        undefined
    )
    const [bookmarkList, setBookmarkList] = useState<BookmarkAction[]>([])

    const handleSnackbarClose = () => {
        setOpenSnackBar(false)
    }

    const snackbarAction = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );
    useEffect(() => {

      if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpenSnackBar(true);
        } else if (snackPack.length && messageInfo && openSnackBar) {
            // Close an active snack when a new one is added
            setOpenSnackBar(false);
        }
    }, [snackPack, messageInfo, openSnackBar]);


    const handleExited = () => {
        setMessageInfo(undefined)
    }

    // make the redux sync with the database 

    const setBookmarkInternal = (pageId: string) => {
        if(dashboardStatus === STATUS.SUCCEEDED){
            let dashboardMetadataTmp = updateDashboardMetadataBookmark(dashboardMetadata, {pageId: pageId, isBookmarked: true})
            dispatch(dashboardMetadataAdded(dashboardMetadataTmp))
        }
    }

    const setUnbookmarkInternal = (pageId: string) => {
        if(dashboardStatus === STATUS.SUCCEEDED){
            let dashboardMetadataTmp = updateDashboardMetadataBookmark(dashboardMetadata, {pageId: pageId, isBookmarked: false})
            dispatch(dashboardMetadataAdded(dashboardMetadataTmp))
        }
    }

    // handle params in embeded link
    let fullParams: Object = dashboardPage.params
    if(uuid){
        let uuidObj: { [key: string]: string } = dashboardPage.uuids.reduce((acc,key) => {
            acc[key] = uuid
            return acc
        },{} as { [key: string]: string })
        
        fullParams = {...fullParams, ...uuidObj, dashboard_id: id}
    }
    const modEmbededLink = Object.keys(fullParams).length > 0  ? 
    dashboardPage.embededLink + "?params=" + encodeURIComponent(JSON.stringify(fullParams))
    :
    dashboardPage.embededLink

    function isAccessTokenExpired() {
        return Date.now() / 1000 >= expiresAt
    }

    const extendUuidLife = async () => {
        if (
            uuid
        ) {
            if(
                accessTokenStatus === "succeeded" &&
                isAccessTokenExpired()
                 
            ){
                try {
                    dispatch(fetchAccessToken())
                        .unwrap()
                        .then(async (accessTokenTmp: string) => {
                            let {success, res} = await extendUuid(id, uuid, accessTokenTmp)
                            if(!success){
                                setErrorMessage("เกิดปัญหาระหว่างการต่ออายุแดชบอร์ด กรุณารีเฟรชหน้าใหม่อีกครั้ง")
                            }
                        })
                        .catch((error) => {
                            setIsLoading(false)
                            setErrorMessage(error)
                        })
                } catch (error) {
                    setErrorMessage("เกิดปัญหาระหว่างการต่ออายุแดชบอร์ด กรุณารีเฟรชหน้าใหม่อีกครั้ง")
                } 
            } else {
                let {success, res} = await extendUuid(id, uuid, accessToken)
                if(!success){
                    setErrorMessage("เกิดปัญหาระหว่างการต่ออายุแดชบอร์ด กรุณารีเฟรชหน้าใหม่อีกครั้ง")
                }
            }
            
        } 
    }

    const REFRESH_TIME = 9 * 60 * 1000 // in millisecond

    // function that calls function "callback" after "delay" second
    function callbackInterval(callback: () => void, delay: number) {
        const cachedCallback = useRef<() => void>()

        useEffect(() => {
            cachedCallback.current = callback
        }, [callback])

        useEffect(() => {
            if (delay !== 0) {
                let id = setInterval(() => cachedCallback?.current?.(), delay)
                return () => clearInterval(id)
            }
        }, [delay])
    }

    callbackInterval(extendUuidLife, REFRESH_TIME)

    let title = "Travel Link | "
    if (isLoading) {
        title = title + "Loading"
    } else {
        title = title + dashboardPage.pageTitle
    }
    useTitle(title)

    

  return (
    <CustomContainer>
        <LogInPopUp open={openLogInPopUp} setOpen={() => {window.location.href="/dashboard"}} redirect_uri={window.location.pathname}/>
        <ThemeProvider theme={theme}>
            {
                isLoading ?
                <DashboardSkeleton />
                :
                <>
                    <SharePopup open={openSharePopup} setOpen={setOpenSharePopup} />
                    <div style={{display: 'flex', justifyContent:'space-between', alignItems:'middle',  marginBottom:'10px', marginTop:'20px'}}>
                        <div style={{display:'flex',justifyContent:'flex-start',width:'500px', alignItems:'center'}}>
                            {
                                dashboardGallery.tags.map((tag) => 
                                <DashboardTagElement tag={tag} pathname='/dashboard'/>
                                )
                            }
                            <Divider orientation='vertical' flexItem sx={{ borderRightWidth: 2, borderColor:'rgba(0,0,0,0.5)', margin: "16px 0" }} />
                            <BookmarkElement 
                            pageId={dashboardPage.pageId} 
                            isBookmarked={isBookmarked}
                            setIsBookmarked={setIsBookmarked}
                            setOpenSnackBar={setOpenSnackBar}
                            setSnackPack={setSnackPack}
                            urlLink={dashboardPage.link}
                            bookmarkTooltipText={isMultipleType ? <>กดเพื่อติดตามแดชบอร์ดหน้า<br/><span style={{fontWeight:'500'}}>{dashboardPage.pageName}</span></> : "กดเพื่อติดตามแดชบอร์ดนี้"}
                            unbookmarkTooltipText="กดเพื่อเลิกติดตาม"
                            bookmarkSnackbarText={`ติดตามแดชบอร์ด "${dashboardPage.pageTitle}" เรียบร้อยแล้ว`}
                            unbookmarkSnackbarText="เลิกติดตามแดชบอร์ดเรียบร้อยแล้ว"
                            callBookmarkAPI={bookmarkDashboard}
                            callUnbookmarkAPI={unbookmarkDashboard}
                            setBookmarkInternal={setBookmarkInternal}
                            setUnbookmarkInternal={setUnbookmarkInternal}
                            handleLoginPopUpFromBookmark={() => {}}
                            // bookmarkIconStyle
                            />
                        </div>
                        <Box marginLeft="auto" display={{md:"block", sm:"block", xs:"none"}} marginY= 'auto'>
                            {
                                isMultipleType && dashboardGallery.pageList.length > 0 ?
                                    <ThemeProvider theme={dynamicSelectorTheme}>
                                        <Select
                                        value={pageName}
                                        onChange={(e) => setPageName(e.target.value)}
                                        sx={{
                                            '.MuiInputBase-input':{
                                                paddingRight: '50px !important',
                                                paddingLeft: '30px',
                                                textAlign: 'center'
                                            }
                                        }}
                                        >
                                        {
                                            dashboardGallery.pageList.map(({pageName}) => 
                                            <MenuItem key={pageName} value={pageName} onClick={() => handleProvinceURL(pageName)}>{pageName}</MenuItem>
                                            )
                                        }
                                    </Select>

                                    </ThemeProvider>
                                :
                                <></>
                            }
                            
                        </Box>
                    </div>

                    <Box marginLeft="auto" display={{md:"none", sm:"none", xs:"block"}} marginBottom={1.25}>
                            {
                                isMultipleType && dashboardGallery.pageList.length > 0 ?
                                    <ThemeProvider theme={dynamicSelectorTheme}>
                                        <Select
                                        value={pageName}
                                        onChange={(e) => setPageName(e.target.value)}
                                        sx={{
                                            '.MuiInputBase-input':{
                                                paddingRight: '50px !important',
                                                paddingLeft: '30px',
                                                textAlign: 'center'
                                            }
                                        }}
                                        fullWidth
                                        >
                                        {
                                            dashboardGallery.pageList.map(({pageName}) => 
                                            <MenuItem key={pageName} value={pageName} onClick={() => handleProvinceURL(pageName)}>{pageName}</MenuItem>
                                            )
                                        }
                                    </Select>

                                    </ThemeProvider>
                                :
                                <></>
                            }
                        </Box>

                    <div className={style.dashboardBox}>
                        <Iframe
                            width="100%"
                            height="100%"
                            url={
                                modEmbededLink
                            }
                            frameBorder={0}
                        />
                    </div>
                    <div style={{display:'flex', justifyContent:'flex-end', whiteSpace:'nowrap', margin:'10px 0'}}>
                            <a href="https://lin.ee/2UhQtlT" target="_blank">
                                <TVLButton lean size='medium' sx={{marginRight:'10px'}}>
                                    <Box display='flex'>
                                        <img
                                            src={line_logo}
                                            className={style.line_logo}
                                        />
                                        <Box margin='auto auto auto 10px' display={{md:'block', sm:'block', xs:'none'}} >
                                            {" "}
                                            เสนอความคิดเห็นผ่าน Line OA
                                        </Box>

                                    </Box>
                                </TVLButton>

                            </a>

                        <TVLButton lean size='medium' onClick={() => setOpenSharePopup(true)}>

                            <Box display='flex'>
                                <img src={share} className={style.line_logo} style={{width:'20px'}}/>
                                <Box margin='auto auto auto 10px' display={{md:'block', sm:'block', xs:'none'}} >
                                    {" "}
                                    แชร์
                                </Box>

                            </Box>
                        </TVLButton>
                    </div>
                    <div style={{marginBottom:'50px'}}>
                        <div className={style.detail}>รายละเอียด</div>

                        <Box textAlign='left' display='flex' marginBottom={3}>
                            <Box className={style.header} width={100}>
                                จัดทำโดย:
                            </Box>
                            <Box flex={1} className={style.text}>
                                Travel Link
                            </Box>
                        </Box>
                        <Box textAlign='left' display='flex' marginBottom={3}>
                            <Box className={style.header} width={100}>
                                วันที่สร้าง:
                            </Box>
                            <Box flex={1} className={style.text}>
                                {formatDate(dashboardGallery.createdDate)}
                            </Box>
                        </Box>
                        <Box textAlign='left' display={{md:'flex', sm:'flex', xs:'block'}}>
                            <Box className={style.header} marginBottom={{md: 0, sm: 0, xs: 2}} width={100}>
                                คำอธิบาย:
                            </Box>
                            <Box flex={1} className={style.text} display={{md:'flex', sm:'flex', xs:'none'}}>
                                {dashboardGallery.fullDescription.replace('<pageName>',dashboardPage.pageName)}
                            </Box>
                            <Box className={style.text} display={{md:'none', sm:'none', xs:'block'}}>
                                {dashboardGallery.fullDescription.replace('<pageName>',dashboardPage.pageName)}
                            </Box>
                        </Box>
                    </div>
                    <Divider sx={{marginBottom:'50px'}}/>
                    <div className={style.detail}>แดชบอร์ดที่คล้ายคลึงกัน</div>
                    <Grid container spacing={3} justifyContent='space-between' marginBottom={5}>
                        {
                            similarDashboards.map((similarDashboard) => (
                                <Grid item md={4} sm={6} xs={12}>
                                    <DashboardCard 
                                    dashboardMetadata={similarDashboard} 
                                    searchText=''
                                    setOpenSnackBar={setOpenSnackBar}
                                    setSnackPack={setSnackPack}
                                    handleLoginPopUp={()=> {}}
                                    handleLoginPopUpFromBookmark={() => {}}
                                    pathname='/dashboard'
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </>

            }
            
            <Snackbar
            key={messageInfo ? messageInfo.key : undefined}
            TransitionProps={{ onExited: handleExited }}
            message={messageInfo ? messageInfo.message : undefined}
            open={openSnackBar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            action={snackbarAction}/>
            
        </ThemeProvider>
            
    </CustomContainer>

    
  )
}

export default DashboardPreview2